define(['lodash',
    'prop-types',
    'coreUtils',
    'componentsCore',
    'santa-components',
    'tpaComponents/components/tpaWidgetNative/tpaWidgetNativeRequirementChecker',
    'create-react-class'
], function (_,
             PropTypes,
             coreUtils,
             componentsCore,
             santaComponents,
             tpaWidgetNativeRequirementChecker,
             createReactClass) {
    'use strict';

    const TPAWidgetNativeName = 'wysiwyg.viewer.components.tpapps.TPAWidgetNative';
    const TPAWidgetNativeSkin = 'wysiwyg.viewer.skins.TPAWidgetNativeSkin';

    const compRegistrar = componentsCore.compRegistrar;
    const {santaTypesDefinitions, utils: santaUtils} = santaComponents;
    const createReactElement = santaUtils.createReactElement;
    const privateSantaTypes = santaUtils.createSantaTypesDefinitions({
        compProps: PropTypes.object,
        boltComponents: PropTypes.object
    }, 'NativeComponentSantaTypes');

    const fontsTypes = {
        widgetStyle: santaTypesDefinitions.NativeComponentSantaTypes.widgetStyle
    };
    const cssTypes = {
        ReactComponent: santaTypesDefinitions.NativeComponentSantaTypes.getComponent,
        styleData: santaTypesDefinitions.NativeComponentSantaTypes.widgetStyle,
        viewMode: santaTypesDefinitions.NativeComponentSantaTypes.viewMode,
        getStyleData: santaTypesDefinitions.Component.getStyleData,
        themeData: santaTypesDefinitions.Theme.THEME_DATA,
        scriptsLocationMap: santaTypesDefinitions.ServiceTopology.scriptsLocationMap,
        structure: santaComponents.santaTypesDefinitions.Component.structure.isRequired,
        layout: santaTypesDefinitions.Component.layout,
        getClientSpecMapEntry: santaComponents.santaTypesDefinitions.getClientSpecMapEntry,
        compData: santaTypesDefinitions.Component.compData
    };
    const propTypes = {
        ReactComponent: santaTypesDefinitions.NativeComponentSantaTypes.getComponent,
        widgetStyle: santaTypesDefinitions.NativeComponentSantaTypes.widgetStyle,
        formFactor: santaTypesDefinitions.NativeComponentSantaTypes.formFactor,
        viewMode: santaTypesDefinitions.NativeComponentSantaTypes.viewMode,
        appLoadBI: santaTypesDefinitions.NativeComponentSantaTypes.appLoadBI,
        loadFonts: santaTypesDefinitions.NativeComponentSantaTypes.loadFonts,
        id: santaComponents.santaTypesDefinitions.Component.id,
        structure: santaComponents.santaTypesDefinitions.Component.structure,
        style: santaTypesDefinitions.Component.style,
        styleId: santaTypesDefinitions.Component.styleId,
        compActions: santaTypesDefinitions.Component.compActions,
        compData: santaTypesDefinitions.Component.compData,
        handleAction: santaTypesDefinitions.Behaviors.handleAction,
        defaultContentArea: santaTypesDefinitions.Container.defaultContentArea,
        getStyleData: santaTypesDefinitions.Component.getStyleData,
        getClientSpecMapEntry: santaComponents.santaTypesDefinitions.getClientSpecMapEntry,
        isInSSR: santaComponents.santaTypesDefinitions.isInSSR,
        skin: coreUtils.staticValue(PropTypes.string, TPAWidgetNativeSkin),
        windowScrollEvent: santaComponents.santaTypesDefinitions.SiteAspects.windowScrollEvent,
        pageId: santaTypesDefinitions.Component.pageId,
        registerToSiteReady: santaTypesDefinitions.NativeComponentSantaTypes.registerToSiteReady,
        handleEvent: santaTypesDefinitions.NativeComponentSantaTypes.handleEvent,
        languageCode: santaComponents.santaTypesDefinitions.RendererModel.languageCode,
        accessibilityEnabled: santaTypesDefinitions.isVisualFocusEnabled,
        compProps: privateSantaTypes.compProps,
        isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen,
        deadComponentTranslations: santaTypesDefinitions.NativeComponentSantaTypes.deadComponentTranslations,
        registerReLayoutPending: santaComponents.santaTypesDefinitions.Layout.registerReLayoutPending.isRequired,
        isMeshLayoutMechanism: santaComponents.santaTypesDefinitions.Layout.isMeshLayoutMechanism.isRequired,
        reLayoutIfPending: santaComponents.santaTypesDefinitions.Layout.reLayoutIfPending.isRequired,
        boltComponents: privateSantaTypes.boltComponents
    };

    function getCustomCompCss(getSkin, props) {
        const styleId = props.styleId;
        const {
            getStyleData,
            themeData,
            siteZoomRatio,
            invertedZoomRatio,
            orientationZoomFix,
            mobileZoom,
            scriptsLocationMap
        } = props;
        const mobileData = {siteZoomRatio, invertedZoomRatio, orientationZoomFix, mobileZoom};
        const serviceTopology = {scriptsLocationMap};

        const customGetSkin = () => TPAWidgetNativeSkin;
        const styleData = getStyleData(styleId);
        styleData.skin = TPAWidgetNativeSkin;
        const css = santaComponents.utils.skinsRenderer.createSkinCss(TPAWidgetNativeSkin, _.get(styleData, 'style.properties', {}), themeData, styleId, mobileData, serviceTopology, customGetSkin);

        return css && {[styleId]: css};
    }

    getCustomCompCss.cssTypes = cssTypes;

    function isCompActionNamespace(eventName) {
        return _.includes(eventName, '.');
    }

    function getEventHandlers(compActions, handleAction, registerToSiteReady) {
        return _.reduce(compActions, (acc, eventInfo, eventName) => {
            const eventHandler = (...argsArray) => registerToSiteReady(() => handleAction(eventInfo, {payload: JSON.stringify(argsArray)}));
            if (isCompActionNamespace(eventName)) {
                return _.set(acc, eventName, eventHandler);
            }
            acc[eventName] = eventHandler;
            return acc;
        }, {});
    }

    function getCustomCompFonts({widgetStyle}) {
        return widgetStyle && _(widgetStyle.styleParams.fonts)
            .pickBy('fontStyleParam')
            .map('family')
            .value();
    }

    getCustomCompFonts.fontsTypes = fontsTypes;

    const TPAWidgetNative = createReactClass({
        statics: {
            santaTypeDefinitions: privateSantaTypes
        },
        mixins: [
            componentsCore.mixins.customSkinBasedComp({customCssFunc: getCustomCompCss, customFontsFunc: getCustomCompFonts}),
            componentsCore.mixins.createChildComponentMixin,
            componentsCore.mixins.blockOuterScrollMixin
        ],
        propTypes,

        getInitialState() {
            return {
                scrollSubscribers: [],
                didLayoutSubscribers: [],
                raven: coreUtils.integrations.ravenUtils.createNewInstance()
            };
        },

        /**
         *  Returns an object of {eventName: function} which will be passed on the props to the inner React component.
         *  eventName is the name of the function and the function is a function that when triggered by the inner component, invokes this.props.handleEvent
         *  with the eventInfo. the handleEvent will send a postMessage to the worker with the eventInfo and the worker will invoke the callback that
         *  is saved in the worker (see wixcode-sdk/postMessage.es6)
         * @param events: {eventName: eventInfo (callbackId, contextId)}
         * @returns Object of {eventName: function}
         */
        createEventHandlers(events) {
            return _.reduce(events, (allEvents, {callbackId, contextId}, eventName) => _.set(allEvents, eventName, (...eventArgs) => this.props.handleEvent({callbackId, contextId, eventArgs})), {});
        },

        getControllerProps() {
            let wixCodeProps;
            let events;
            if (this.props.compProps && this.props.compProps.wixCodeProps && this.props.compProps.events) {
                wixCodeProps = this.props.compProps.wixCodeProps;
                events = this.props.compProps.events;
            } else {
                wixCodeProps = this.props.compData.wixCodeProps;
                events = this.props.compData.events;
            }
            return {wixCodeProps, events};
        },

        updateLayout() {
            if (!this.props.isMeshLayoutMechanism) {
                this.props.registerReLayoutPending(this.props.id);
                this.props.reLayoutIfPending();
            }
        },

        getCompProps() {
            const {wixCodeProps, events} = this.getControllerProps();
            let eventHandlers;
            if (events) {
                //new events flow
                eventHandlers = this.createEventHandlers(events);
            } else {
                //old events flow - TODO: remove when new flow is in production in the wixcode-sdk side.
                eventHandlers = getEventHandlers(this.props.compActions, this.props.handleAction, this.props.registerToSiteReady);
            }
            const props = {
                style: this.props.widgetStyle,
                id: this.props.id,
                viewMode: this.props.viewMode,
                formFactor: this.props.formFactor,
                dimensions: this.props.style,
                appLoadBI: this.props.appLoadBI,
                registerToComponentDidLayout: this.registerToComponentDidLayout,
                registerToScroll: this.registerToScroll,
                loadFonts: this.props.loadFonts,
                raven: this.state.raven,
                styleId: this.props.styleId,
                pageId: this.props.pageId,
                onSiteReady: this.props.registerToSiteReady,
                accessibilityEnabled: this.props.accessibilityEnabled,
                blockScroll: this.blockScroll,
                unblockScroll: this.unblockScroll,
                updateLayout: this.updateLayout,
                boltComponents: this.props.boltComponents,
                isScrollExperimentOpen: this.props.isExperimentOpen('bv_restoreScroll') //TODO: remove when ecom fix the scroll
            };

            if (this.props.isExperimentOpen('sv_addPropsToHostInNativeComponent')) {
                return _.merge({host: props}, wixCodeProps, eventHandlers);
            }
            return _.merge(props, {host: props}, wixCodeProps, eventHandlers);
        },

        blockScroll() {
            const blockOnWheel = () => {
                this.refs[''].addEventListener('wheel', this.blockOuterScroll);
                this.refs[''].addEventListener('touchmove', this.blockOuterScroll);
            };

            if (this.refs['']) {
                blockOnWheel();
            } else {
                this.registerToComponentDidLayout(blockOnWheel);
            }
        },

        unblockScroll() {
            const unblockOnWheel = () => {
                this.refs[''].removeEventListener('wheel', this.blockOuterScroll);
                this.refs[''].removeEventListener('touchmove', this.blockOuterScroll);
            };

            if (this.refs['']) {
                unblockOnWheel();
            } else {
                this.registerToComponentDidLayout(unblockOnWheel);
            }
        },

        registerToScroll(callback) {
            this.props.windowScrollEvent.registerToScroll(this);
            this.state.scrollSubscribers.push(callback);
        },

        registerToComponentDidLayout(callback) {
            this.state.didLayoutSubscribers.push(callback);
        },

        componentDidLayout() {
            this.state.didLayoutSubscribers.forEach(cb => cb());
        },

        onScroll(scrollArgs) {
            this.state.scrollSubscribers.forEach(cb => cb(scrollArgs));
        },

        componentWillUnmount() {
            this.props.windowScrollEvent.unregisterToScroll(this);
        },

        getDeadCompProperties() {
            if (this.props.isInSSR) {
                return {
                    children: null
                };
            }

            const itemData = {
                height: this.props.structure.layout.height,
                width: this.props.structure.layout.width,
                applicationId: this.props.compData.applicationId,
                deadComponentTranslations: this.props.deadComponentTranslations
            };

            return {
                style: {
                    height: '',
                    backgroundColor: 'rgba(255, 255, 255, .9)'
                },
                children: this.createChildComponent(itemData, 'wysiwyg.viewer.components.tpapps.TPAWidgetNativeDeadcomp', 'TPAWidgetNativeDeadcomp')
            };
        },

        getSkinProperties() {
            if (this.props.ReactComponent) {
                return {
                    '': {
                        style: {height: ''},
                        children: createReactElement(this.props.ReactComponent, this.getCompProps())
                    }
                };
            }

            return {'': this.getDeadCompProperties()};
        }
    });

    compRegistrar.register(TPAWidgetNativeName, TPAWidgetNative);
    componentsCore.dataRequirementsCheckerRegistrar.registerCheckerForCompType(TPAWidgetNativeName, tpaWidgetNativeRequirementChecker.requireAndRegisterComponent);
    return TPAWidgetNative;
});
